<template>
  <main class="container mx-auto pt-12">
    <h1 class="font-bold text-2xl text-blue-darkest mb-4">{{ t('title') }}</h1>

    <a-spinner v-if="loadTask.isRunning" size="2rem" class="mt-4" />
    <a-table
      v-else-if="test"
      :columns="[
        { name: 'scale' },
        { name: 'anchor', label: t('anchorWords') },
        { name: 'description', label: t('description') }
      ]"
      :data="test.scale"
      class="rating-table"
    >
      <template #header-scale>
        <div class="text-center">
          {{ t('scaleValue') }}
        </div>
      </template>
      <template #cell-scale="{ index }">
        <div class="text-center">
          {{ index + 1 }}
        </div>
      </template>
    </a-table>
    <a-alert
      v-else-if="!test || loadTask.error !== null"
      icon
      variant="danger"
      class="mt-4 w-1/4"
    >
      {{ t('noTestFound') }}
    </a-alert>
  </main>
</template>

<script lang="ts">
import { DeepReadonly, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { Test } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'ProgramRatingScale',
  props: { testId: { type: String } },
  setup(props) {
    const program = useStore(programStore);
    const test = ref<DeepReadonly<Test> | null>(null);

    watchEffect(() => {
      if (program.tests !== null) {
        const currentTest = program.tests.find(t => t.id === props.testId);

        if (currentTest !== undefined) {
          test.value = currentTest;
        }
      }
    });

    return {
      test,
      loadTask: program.loadSchoolsAndTests,
      t: useI18n().t
    };
  }
});
</script>

<style lang="postcss" scoped>
.rating-table ::v-deep(th:last-child) {
  width: 50%;
}
</style>

<i18n>
{
  "en": {
    "anchorWords": "Anchor words",
    "description": "Behavioral Descriptor",
    "noTestFound": "No test found.",
    "scaleValue": "Scale value",
    "title": "Rating scale explained"
  },
  "fr": {
    "anchorWords": "Descripteurs",
    "description": "Description du comportement",
    "noTestFound": "Aucun examen trouvé.",
    "scaleValue": "Note sur l'échelle",
    "title": "Fonctionnement de l'échelle d'évaluation"
  }
}
</i18n>
