export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "anchorWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anchor words"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behavioral Descriptor"])},
        "noTestFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No test found."])},
        "scaleValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale value"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating scale explained"])}
      },
      "fr": {
        "anchorWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripteurs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du comportement"])},
        "noTestFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun examen trouvé."])},
        "scaleValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note sur l'échelle"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnement de l'échelle d'évaluation"])}
      }
    }
  })
}
