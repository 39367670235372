import { render } from "./ProgramRatingScale.vue?vue&type=template&id=0fdc6c6b&scoped=true"
import script from "./ProgramRatingScale.vue?vue&type=script&lang=ts"
export * from "./ProgramRatingScale.vue?vue&type=script&lang=ts"

import "./ProgramRatingScale.vue?vue&type=style&index=0&id=0fdc6c6b&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-0fdc6c6b"
/* custom blocks */
import block0 from "./ProgramRatingScale.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script