
import { DeepReadonly, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { Test } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'ProgramRatingScale',
  props: { testId: { type: String } },
  setup(props) {
    const program = useStore(programStore);
    const test = ref<DeepReadonly<Test> | null>(null);

    watchEffect(() => {
      if (program.tests !== null) {
        const currentTest = program.tests.find(t => t.id === props.testId);

        if (currentTest !== undefined) {
          test.value = currentTest;
        }
      }
    });

    return {
      test,
      loadTask: program.loadSchoolsAndTests,
      t: useI18n().t
    };
  }
});
